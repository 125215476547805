import './LandingPage.css';
import {useState} from "react";
import {toast} from "react-hot-toast";
import axios from "axios";
import logo from './assets/truq-logo.png';
import driveHub from './assets/svg/driveHub.svg';
import truck from './assets/svg/truck.svg'
import siju from './assets/svg/siju.svg';
import truqPay from './assets/svg/truqPay.svg';
import check from './assets/svg/material-symbols_check.svg';
import phone from './assets/svg/phone.svg';
import mail from './assets/svg/mail.svg';
import menu from './assets/svg/menu.svg';
import back from './assets/svg/arrow-left.svg';
import success from './assets/svg/success.svg';
import { generalAppInfo } from './utils/constants';
import { getBaseUrl, getBaseUrlEnv } from './utils/axios.service';
import { Spinner } from '@chakra-ui/react';



const LandingPage = () => {
  const [loading, setLoading] = useState(false);
  const [navLoading, setNavLoading] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [show, setShow] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
 
  const validateSubscribe =  !email || emailErr;

  const data = [
    {image:driveHub, title: "Drivehub by truQ", content: "For ambitious logistics operators, seamlessly digitize your trips, collect payments, access financing, enjoy discounts on your expenses and earn more money,"},
    {image:truck, title: "Move by truQ", content: "For a seamless moving experience,offering everything you need, before, during, and after your move."},
    {image:siju, title: "Siju by truQ", content:"For success-hungry businesses, unlocking efficient logistics operations and powering revenue milestones."},
    {image:truqPay, title:"truQ Pay", content:"Empowering Africa's commercial fleet operators with a suite of financial solutions to help them win."},
  ]
 
    const validateEmail = (email) => {
        return !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/.test(email);
    };
    
    const handleEmailError = (event) => {
        if (validateEmail(event.target.value))
          setEmailErr("Please enter a valid email");
        else setEmailErr(null);
    };

   const toggleSideBar = () => {
    setShow(!show);
   }

   const hideIcon = () => {
    setTimeout(() => {
      setSubscribed(false);
    }, 3000);
   }

  const getInstantQuote = (type) => {
    type === 'nav' ? setNavLoading(true) : setLoading(true);
    let organisation = getBaseUrlEnv() === "PRODUCTION" ? "TRU_Z9WE" : "TRU_H0D4";
    let PK = getBaseUrlEnv() === "PRODUCTION" ? generalAppInfo.SIJU_PK : generalAppInfo.SIJU_STAGING_PK;
    let headers = {
      Authorization: `Bearer ${PK} `, "Content-Type": "application/json", organisation,
    };

    axios.post(
      `${getBaseUrl().web}/booking-service/booking/booking-trip/check_quote/`, {},
      { headers }
    ).then((results) => {
      const { data: responseData } = results;
      const baseURL = organisation === "TRU_H0D4" ? "https://dodge-checkout-beta.vercel.app" : "https://checkout.siju.it";
      const newCheckoutUrl = `${baseURL}/${responseData.data.quote.reference}`;
      const popupWindow = window.open(newCheckoutUrl, "_blank");
      if (popupWindow && !popupWindow.closed && typeof popupWindow.closed === 'undefined') popupWindow.focus();
      type === 'nav' ? setNavLoading(false) : setLoading(false);
    }).catch(async (err) => {
      type === 'nav' ? setNavLoading(false) : setLoading(false);
      const errMessage =
        err && err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : "Unable to initiate trip at the moment";
      toast.error(errMessage);
    });
  }

  const subscribeEmail = (e) => {
    e.preventDefault()
    setLoadingSubscription(true);
    const payload = {
      email,
    };

    axios.post(`${getBaseUrl().analytics}/messaging-service/subscribers/subscribe`, payload)
      .then(() => {
        setLoadingSubscription(false);
        setSubscribed(true);
        hideIcon();
      })
      .catch(() => {
        setLoadingSubscription(false);
        setSubscribed(true);
        hideIcon();
      });
  };
  return (
    <div className='container'>
      <nav>
        <img src={logo} alt='truq' className='logo'/>
        <div className={`${show ? "hide-nav-links" : "nav-links"}`}>
          <a href='https://app.mytruq.com/auth/login' className='link'>Login</a>
          <button onClick={() => getInstantQuote('nav')}>
            Get Instant Quote &nbsp;
            {navLoading && <Spinner color="white" className="spinner" />}
          </button>
          <img src={menu} alt='menu' onClick={toggleSideBar} className='menu'/>
        </div>
        <div className={`${show ? "show-side-bar" : "hide-side-bar"}`}>
            <img src={back} alt='collapse_menu' onClick={toggleSideBar} width={20} height={20}/>
            <button onClick={() => getInstantQuote('nav')}>
              Get Instant Quote &nbsp;
              {navLoading && <Spinner color="white" className="spinner" />}
            </button>
            <a href='https://app.mytruq.com/auth/login' className='link'>Login</a>
        </div>
      </nav>

      <section>
        <header>
          <h3 className='header-1'>Our New Website Is Coming!</h3>
          <h2 className='header-2'>A New Destination for Everything truQ</h2>
        </header>

        <div className='inner-section'>
          <div className='left-section'>
            {data.map((items, index) => (
              <div key={index}>
                <img src={items.image} alt='icons' width={21} height={21}/>
                <p className='title'>{items.title}</p>
                <p className='content'>{items.content}</p>
              </div>
            ))}
          </div>

          <div className='right-section'>
            <h3>Moving Soon?</h3>
            <p>Get connected to the closest vehicle and everything else you need for a convenient moving experience</p>

            <ul>
              <li>
                <div>
                  <img src={check} alt='check' height={18}/>
                  <p>Loaders</p> 
                </div>
                <div>
                  <img src={check} alt='check' height={18}/>
                  <p>Packaging materials</p> 
                </div>
              </li>
              <li>
                <div>
                  <img src={check} alt='check' height={18}/> 
                  <p>Domestic artisans</p></div>
                <div> 
                  <img src={check} alt='check' height={18}/> 
                  <p>Movers</p> 
                </div>
              </li>
              <li>
                <div>
                  <img src={check} alt='check' height={18}/> 
                  <p>24/7 Support</p>
                </div>
                <div>
                  <img src={check} alt='check' height={18}/> 
                  <p>Insurance</p>
                </div>
              </li>
            </ul>

            <button onClick={()=> getInstantQuote('body')}> 
              Get Instant Quote &nbsp;
              {loading && <Spinner color="white" className="spinner" /> }
            </button>

            <div className='info'>
            <p>Be the first to know when our new website goes live!</p>
            <div>
              <input type='text' placeholder='Email Address'
              value={email}
              onChange={(e) => {setEmail(e.target.value.toLowerCase()); handleEmailError(e)}}
              />
              
              <button
                style={validateSubscribe ? { backgroundColor: "#808080" } : { backgroundColor: "#2D3892" }}
                disabled={validateSubscribe}
                onClick={subscribeEmail}
                className='subscribe'
              >
                Yes, keep me posted
                {loadingSubscription && <Spinner color="white" style={{width: "12px", height: "12px", marginBottom: '5px'}}/>}
              </button>
              {subscribed && <img src={success} alt='subscribed' className='subscribed'/>}
            </div>
            <span> {emailErr} </span>
            </div>

            <div className='contact'>
              <img src={phone} alt='call_us'/>
              <a href='tel:+2348168735873'>08168735873</a> 
              <img src={mail} alt='mail_us'/>
              <a href='mailto:Contact@mytruq.com'>contact@mytruq.com</a>
            </div>
          </div>
        </div>
        
      </section>
    </div>

  )
}

export default LandingPage;